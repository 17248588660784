import * as React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Link from "../components/LocalizedLink";

interface IProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    post: {
      data: {
        title: {
          text: string;
        };
        body: {
          text: string;
          html: string;
        };
        post_date: {
          text: string;
        };
        cover: any;
      };
    };
  };
  pageContext: any;
  location: Location;
}
const BlogPostTemplate = ({ data, pageContext, location }: IProps) => {
  const post = data.post;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle} pageContext={pageContext}>
      <SEO
        title={post.data.title.text}
        description={post.data.body.text.substr(0, 200)}
      />
      <div className="blog-area pt-120 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <article className="postbox post format-image mb-40">
                <div className="postbox__thumb mb-35">
                  <Img
                    fluid={post.data.cover.localFile.childImageSharp.fluid}
                  />
                </div>
                <div className="postbox__text bg-none">
                  <div className="post-meta mb-15">
                    <span>
                      <i className="far fa-calendar-check"></i>{" "}
                      {post.data.post_date}
                    </span>
                  </div>
                  <h3 className="blog-title">{post.data.title.text}</h3>
                  <div
                    className="post-text mb-20"
                    dangerouslySetInnerHTML={{ __html: post.data.body.html }}
                  ></div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($uid: String!, $locale: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    post: prismicBlogPost(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        post_date(formatString: "YYYY/MM/DD")
        title {
          text
        }
        body {
          html
          text
        }
        cover {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
